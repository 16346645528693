<h1 class="m-5 text-center">Galleria</h1>

<p class="m-5 text-center">Ecco alcuni degli scatti più belli che ci ritraggono negli eventi a cui abbiamo partecipato.</p>

<hr>
<div class="m-5 text-center">
    <h2 class="m5">"A Celtic Holy Night"</h2>
    <p class="m-5">Albino (BG), 1 dicembre 2024, concerto organizzato con il supporto di <a target="_blank" href="https://artesulserio.com">Arte sul Serio</a>.</p>
    <!--Albino thumbs-->
    <div class="container mt-5">
        <div class="row g-3">
          <div class="col-4" *ngFor="let image of albino2024; let i = index">
            <img
              [src]="image.thumb"
              [alt]="image.alt"
              class="img-thumbnail"
              style="cursor: pointer;"
              (click)="openModal(i, 'albino2024Modal')"
            />
          </div>
        </div>
    </div>

    <!-- Albino Modal -->
    <div
    id="albino2024Modal"
    class="modal fade"
    tabindex="-1"
    aria-hidden="true"
    style="display: none;">
        <div class="modal-dialog modal-fullscreen">
          <div class="modal-content bg-dark text-white">
            <div class="modal-header border-0">
              <button
                type="button"
                class="btn-close btn-close-white"
                aria-label="Close"
                (click)="closeModal('albino2024Modal')"
              ></button>
            </div>
            <div class="modal-body d-flex justify-content-center align-items-center">
              <img
                [src]="images[currentImageIndex].src"
                [alt]="images[currentImageIndex].alt"
                class="img-fluid transition"
                [class.fade-out]="isAnimating"
              />
            </div>
            <p>{{images[currentImageIndex].alt}}</p>
            <div class="modal-footer border-0 justify-content-between">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="previousImage()"
              >
                &laquo;
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                (click)="nextImage()"
              >
                &raquo;
              </button>
            </div>
          </div>
        </div>
    </div>

</div>

<hr>
<div class="m-5 text-center">
    <h2 class="m5">Celtica 2024</h2>
    <p class="m-5"><a target="_blank" href="https://celtica.vda.it/">Celtica</a> 2024, Bosco del Peuterey e Courmayeur.</p>
    <!--Celtica thumbs-->
    <div class="container mt-5">
        <div class="row g-3">
          <div class="col-4" *ngFor="let image of celtica2024; let i = index">
            <img
              [src]="image.thumb"
              [alt]="image.alt"
              class="img-thumbnail"
              style="cursor: pointer;"
              (click)="openModal(i, 'celtica2024Modal')"
            />
          </div>
        </div>
    </div>
      
    <!-- Celtica Modal -->
    <div
    id="celtica2024Modal"
    class="modal fade"
    tabindex="-1"
    aria-hidden="true"
    style="display: none;">
        <div class="modal-dialog modal-fullscreen">
          <div class="modal-content bg-dark text-white">
            <div class="modal-header border-0">
              <button
                type="button"
                class="btn-close btn-close-white"
                aria-label="Close"
                (click)="closeModal('celtica2024Modal')"
              ></button>
            </div>
            <div class="modal-body d-flex justify-content-center align-items-center">
              <img
                [src]="images[currentImageIndex].src"
                [alt]="images[currentImageIndex].alt"
                class="img-fluid transition"
                [class.fade-out]="isAnimating"
              />
            </div>
            <p>{{images[currentImageIndex].alt}}</p>
            <div class="modal-footer border-0 justify-content-between">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="previousImage()"
              >
                &laquo;
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                (click)="nextImage()"
              >
                &raquo;
              </button>
            </div>
          </div>
        </div>
    </div>
</div>