export const albums = {
    albino20241201: [
        {
            src: 'https://www.celticknot.it/images/albino2024/image-1-large.jpg',
            thumb: 'https://www.celticknot.it/images/albino2024/image-1-thumb.jpg',
            alt: '\"A Celtic Holy Night\", foto di Giancarlo Brunelli'
        },
        {
            src: 'https://www.celticknot.it/images/albino2024/image-2-large.jpg',
            thumb: 'https://www.celticknot.it/images/albino2024/image-2-thumb.jpg',
            alt: '\"A Celtic Holy Night\", foto di Giancarlo Brunelli'
        },
        {
            src: 'https://www.celticknot.it/images/albino2024/image-3-large.jpg',
            thumb: 'https://www.celticknot.it/images/albino2024/image-3-thumb.jpg',
            alt: '\"A Celtic Holy Night\", foto di Giancarlo Brunelli'
        },
        {
            src: 'https://www.celticknot.it/images/albino2024/image-4-large.jpg',
            thumb: 'https://www.celticknot.it/images/albino2024/image-4-thumb.jpg',
            alt: '\"A Celtic Holy Night\", foto di Giancarlo Brunelli'
        },
        {
            src: 'https://www.celticknot.it/images/albino2024/image-5-large.jpg',
            thumb: 'https://www.celticknot.it/images/albino2024/image-5-thumb.jpg',
            alt: '\"A Celtic Holy Night\", foto di Giancarlo Brunelli'
        },
        {
            src: 'https://www.celticknot.it/images/albino2024/image-6-large.jpg',
            thumb: 'https://www.celticknot.it/images/albino2024/image-6-thumb.jpg',
            alt: '\"A Celtic Holy Night\", foto di Giancarlo Brunelli'
        },
    ],
    celtica2024: [
        {
            src: 'https://www.celticknot.it/images/celtica2024/image-1-large.jpg',
            thumb: 'https://www.celticknot.it/images/celtica2024/image-1-thumb.jpg',
            alt: 'Celtica 2024'
        },
        {
            src: 'https://www.celticknot.it/images/celtica2024/image-2-large.jpg',
            thumb: 'https://www.celticknot.it/images/celtica2024/image-2-thumb.jpg',
            alt: 'Celtica 2024'
        },
        {
            src: 'https://www.celticknot.it/images/celtica2024/image-3-large.jpg',
            thumb: 'https://www.celticknot.it/images/celtica2024/image-3-thumb.jpg',
            alt: 'Celtica 2024'
        },
        {
            src: 'https://www.celticknot.it/images/celtica2024/image-4-large.jpg',
            thumb: 'https://www.celticknot.it/images/celtica2024/image-4-thumb.jpg',
            alt: 'Celtica 2024'
        },
        {
            src: 'https://www.celticknot.it/images/celtica2024/image-5-large.jpg',
            thumb: 'https://www.celticknot.it/images/celtica2024/image-5-thumb.jpg',
            alt: 'Celtica 2024'
        },
        {
            src: 'https://www.celticknot.it/images/celtica2024/image-6-large.jpg',
            thumb: 'https://www.celticknot.it/images/celtica2024/image-6-thumb.jpg',
            alt: 'Celtica 2024'
        },
        {
            src: 'https://www.celticknot.it/images/celtica2024/image-7-large.jpg',
            thumb: 'https://www.celticknot.it/images/celtica2024/image-7-thumb.jpg',
            alt: 'Celtica 2024'
        },
        {
            src: 'https://www.celticknot.it/images/celtica2024/image-8-large.jpg',
            thumb: 'https://www.celticknot.it/images/celtica2024/image-8-thumb.jpg',
            alt: 'Celtica 2024'
        },
        {
            src: 'https://www.celticknot.it/images/celtica2024/image-9-large.jpg',
            thumb: 'https://www.celticknot.it/images/celtica2024/image-9-thumb.jpg',
            alt: 'Celtica 2024'
        },
        {
            src: 'https://www.celticknot.it/images/celtica2024/image-10-large.jpg',
            thumb: 'https://www.celticknot.it/images/celtica2024/image-10-thumb.jpg',
            alt: 'Celtica 2024'
        },
        {
            src: 'https://www.celticknot.it/images/celtica2024/image-11-large.jpg',
            thumb: 'https://www.celticknot.it/images/celtica2024/image-11-thumb.jpg',
            alt: 'Celtica 2024'
        },
        {
            src: 'https://www.celticknot.it/images/celtica2024/image-12-large.jpg',
            thumb: 'https://www.celticknot.it/images/celtica2024/image-12-thumb.jpg',
            alt: 'Celtica 2024'
        },
    ],
    insubria2024: [],
    celtica2023: [],
}